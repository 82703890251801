import React from "react"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
    <>
      <Layout>
        <center
          style={{
            marginTop: 100,
          }}
        >
          <h2> ❌ Page Not Found</h2>
          <h5>
            Opps! The page you are looking for has been removed or relocated.
          </h5>
        </center>
      </Layout>
    </>
  )
}
